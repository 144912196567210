<template>
  <h2>Как решать эту тему</h2>
  <p>Для начала нужно сказать, что массивы в JavaScript не совсем такие, как в других языках. В том плане, что при обращении к несуществующему элементу массива в других языках, как правило, выдаётся ошибка. В JavaScript это не приводит к ошибке, а значение, которое получаем при обращении - undefined. Я считаю, что это вредит при обучении, поскольку в некоторых случаях некорректный код может работать как ожидалось (ошибка остаётся неявной, незамеченной), а также может приводить к проблемам производительности.</p>
  <p>Например, можно сравнить два варианта кода:</p>
  <Code lang="js" :code="`
    const a = []
    for (let i = 0; i < n; i++) {
        a[i] = 0
    }
  `" />
  <Code lang="js" :code="`
    const a = Array(n)
    for (let i = 0; i < n; i++) {
        a[i] = 0
    }
  `" />
  <p>При n = 1000000 второй код будет работать быстрее более, чем в 5 раз. Это связано с тем, что при добавлении элемента на несуществующую позицию в некоторых случаях массив приходится копировать в другой участок памяти, так как в выделенном заранее участке закончилось место. При достаточно большом количестве элементов копирование может занимать много времени. Во втором примере этого не происходит, поскольку мы заранее определили количество элементов. Это значит, что при добавлении этого количества элементов нам точно хватит места в заранее зарезервированном участке памяти, и лишних копирований производиться не будет</p>
  <p>Кроме того, чтобы лучше понять как работают те или иные методы массивов, мы не будем пользоваться ими при решении задач этой темы, кроме случаев, где этого явно требует условие задачи</p>
  <p>Чтобы избежать неявных ошибок и обращений к несуществующим элементам, а так же для исключения неразрешённых методов, я предлагаю использовать прокси-объект, который поможет сделать ошибки явными. Для создания массива с прокси будем использовать эту функцию:</p>
  <Code lang="js" :code="`
    const A = (n) => {
        const a = Array.isArray(n) ? n : Array(n)
        const proxy = new Proxy(a, {
            get: (_, i) => {
                if (i === Symbol.iterator) {
                    return a[i]
                }
                if (~~i === +i) {
                    if (i < 0 || i >= a.length) {
                        throw new Error('Выход за границы массива: обращение по индексу ' + i + ' при длине ' + a.length)
                    }
                }
                else if (i !== 'length') {
                    throw new Error('Обращение к ' + i)
                }
                return a[i]
            },
            set: (_, i, value) => {
                if (~~i !== +i) {
                    throw new Error('Невозможно записать значение в поле ' + i)
                }
                else if (i < 0 || i >= a.length) {
                    throw new Error('Выход за границы массива: попытка записи по индексу ' + i + ' при длине ' + a.length)
                }
                a[i] = value
            }
        })
        return proxy
    }
  `" />
  <p>Опять же, пока не обязательно понимать, как это работает. Использовать её можно так:</p>
  <Code lang="js" :code="`
    const a = A(3) // создание пустого массива длины 3
    a[0] = 10
    a[1] = 20
    a[2] = 30
    console.log(a.length)
    console.log(a[0], a[1], a[2])
    console.log(a)
    const b = A([10, 20, 30]) // создание прокси над массивом
    console.log(b.length)
    console.log(b[0], b[1], b[2])
    console.log(b)
  `" />
  <p>Результат:</p>
  <CodeResult :code="`
    3
    10 20 30
    [ 10, 20, 30 ]
    3
    10 20 30
    [ 10, 20, 30 ]
  `" />
  <p>Как видим, немного отличается только синтаксис создания массива. В остальном поведение идентично. Используя нативные массивы код выглядел бы так:</p>
  <Code lang="js" :code="`
    const a = Array(3)
    a[0] = 10
    a[1] = 20
    a[2] = 30
    console.log(a.length)
    console.log(a[0], a[1], a[2])
    console.log(a)
    const b = [10, 20, 30]
    console.log(b.length)
    console.log(b[0], b[1], b[2])
    console.log(b)
  `" />
  <p>Также можно использовать цикл for-of, где это уместно:</p>
  <Code lang="js" :code="`
    const a = A([10, 20, 30])
    for (const x of a) {
        console.log(x)
    }
  `" />
  <p>Результат:</p>
  <CodeResult :code="`
    10
    20
    30
  `" />
</template>

<script>
import Code from '@/components/ui/Code'
import CodeResult from '@/components/ui/CodeResult'

export default {
  components: { Code, CodeResult },
  props: ['task']
}
</script>
